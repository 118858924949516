import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';


const firebaseConfig = {
  apiKey: "AIzaSyADbf5fvj1dgjgxGORKHGs7i2cL8wICOsw",
  authDomain: "master-m-a5dbe.firebaseapp.com",
  projectId: "master-m-a5dbe",
  storageBucket: "master-m-a5dbe.firebasestorage.app",
  messagingSenderId: "808359418580",
  appId: "1:808359418580:web:844f7ebbf684e3eb6db941",
  measurementId: "G-XL2D54LC0L"
};




// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging();

export { messaging, getToken, onMessage };