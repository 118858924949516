import React from "react";
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";
import PullToRefresh from 'react-simple-pull-to-refresh';

class Home extends React.Component {

    constructor() {
        super();
        this.state = {
            homeMsg: "Welcome to demo matka play and win",
            number: "Loading",
            wallet: "0",
            results:[],
            width_b:"0%",
            width:"0%",
            nav_display:"none",
            transfer_point:"none",
            whatsapp:"",
            whatsappNum:"",
            pupup:"",
            showPopup:true,
            name:"",
            call:"",
            dialogShow:"none",
            dialogShowError:"none",
            openR:"none",
            closeR:"none",
            openB:"none",
            closeB:"none",
            gali:"",
            starline:"",
        }


    }

    componentDidMount() {

    
        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));
        form.append("f_token", localStorage.getItem("ftoken"));

        axios
            .post(process.env.REACT_APP_URL+"/api2/home.php", form,
            )
            .then((response) => {
                console.log(response);
                if(response.status === 200){

                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }

                    localStorage.setItem("whatsapp", "https://wa.me/+91"+response.data.whatsapp)
                    localStorage.setItem("whatsappNum", "+91"+response.data.whatsapp)

                    localStorage.setItem("wallet", response.data.wallet)
                    localStorage.setItem("min_bet", response.data.min_single)
                    localStorage.setItem("name", response.data.name)


                    this.setState({
                        transfer_point: response.data.transfer_points_status,
                        results:response.data.result,
                        wallet: response.data.wallet,
                        homeMsg:response.data.tet,
                        name:response.data.name,
                        popup:response.data.popup_image,
                        whatsapp:"https://wa.me/+91"+response.data.whatsapp,
                        whatsappNum:response.data.whatsapp,
                        call:"tel://+91"+response.data.whatsapp,
                        gali:response.data.delhi_enable,
                        starline:response.data.starline_enable,
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {
        let showDialog = ()=>{
            this.setState({
                dialogShow:"flex",
            })
        }
        
        let hideDialog = ()=>{
            this.setState({
                dialogShow:"none",
            })
        }

        
        let handleSubmit = async () =>{

            
            let url = "https://api.whatsapp.com/send?phone="+localStorage.getItem("whatsappNum")+"&text=Username%20-%20"+localStorage.getItem("name")+"%0AMobile%20-%20"+localStorage.getItem("mobile")

            // Open the URL in a new tab
            window.open(url, "_blank");

            this.setState({
                showLoading:"none",
            })

        };


        let showDialogError = (or, ob, cr, cb)=>{
            this.setState({
                closeR:cr,
                openR:or,
                closeB:cb,
                openB:ob,
                dialogShowError:"flex",
            })
        }
        
        let hideDialogError = ()=>{
            this.setState({
                dialogShowError:"none",
            })
        }
        const shouldShowDialog = () => {
            const lastShown = localStorage.getItem('lastDialogShown');
            if (!lastShown) {
              return true;
            }
          
            const lastShownDate = new Date(lastShown);
            const now = new Date();
            const hoursDifference = Math.abs(now - lastShownDate) / 36e5;
          
            return hoursDifference >= 24;
          };
        
          const updateLastShownTime = () => {
            localStorage.setItem('lastDialogShown', new Date().toISOString());
          };

          if (shouldShowDialog()) {
            showDialog();
            updateLastShownTime();
          }

        let handleRefresh = ()=>{
            window.location.reload();
        }

        let switchNav = () =>{
            if (this.state.width === "0%"){
                this.setState({
                    width:"100%",
                    width_b:"67%",
                    nav_display:"flex"
                })
            } else {
                this.setState({
                    width:"0%",
                    width_b:"0%",
                    nav_display:"none"
                })
            }
        }

        let marketCloseError = ()=>{
            Swal.fire({
                toast: true,
                title: 'Oops...',
                text: "Market Already Closed, Please come again tomorrow"
            })
        }

        let closePopup = ()=>{
            this.setState({
                showPopup:false
            })
        }

        let shareApp = ()=>{
            navigator.share({
                title: 'Master Matka - Online Matka Play',
                url: process.env.REACT_APP_URL  
            })
        }

        // if (this.state.showPopup){
        //     document.body.style.overflow = 'hidden';
        // } else {
        //     document.body.style.overflow = 'unset';
        // }
        //
        // window.addEventListener('popstate', (event) => {
        //     if (event.state) {
        //         if (this.state.showPopup){
        //             this.setState({
        //                 showPopup:false
        //             })
        //         } else {
        //             window.history.go(-1)
        //         }
        //     }
        // }, false);

        return (<>
            <div className="RootView">
                <div className="RootWidth">

                

                <PullToRefresh onRefresh={handleRefresh}>
                    <div className="loginDiv" style={{paddingBottom:"15%"}}>

                        {/*{ this.state.popup !== "" && this.state.showPopup ?*/}
                        {/*<div className="popup">*/}
                        {/*    <img className="cancel" onClick={closePopup} src="../images/cancel.png" />*/}
                        {/*    <img className="main" src={process.env.REACT_APP_URL+"/admin/"+this.state.popup} />*/}
                        {/*</div> : "" }*/}

                        { localStorage.getItem("mobile") !== "9876543210" ? (
                        <div className="sidenav" style={{width: this.state.width}} onClick={switchNav}>
                            <div className="main-content" style={{width: this.state.width_b}} >

                                <div className="nav-top-head"  style={{display:this.state.nav_display}}>
                                     <img src="../images/man.png"/>
                                    <div className="nav-top-child1">
                                        <span>{this.state.name}</span>
                                        <span>{localStorage.getItem("mobile")}</span>
                                    </div>
                                   
                                </div>

                                
                                <Link to={"/"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/home_nav.png" />
                                        <span>Home</span>
                                    </div>
                                </Link>
{/* 
                                <Link to={"/profile"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/profile.png" />
                                        <span>My Profile</span>
                                    </div>
                                </Link> */}

                                

                                <Link to={"/myBids"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/bid_nav.png" />
                                        <span>My Bids</span>
                                    </div>
                                </Link>



                                <Link to={"/passbook"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/passbook.png" />
                                        <span>Passbook</span>
                                    </div>
                                </Link>

                                
                                <Link to={"/funds"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/funds_nav.png" />
                                        <span>Funds</span>
                                    </div>
                                </Link>

                                
                                <Link to={"/videos"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/videos.png" />
                                        <span>Videos</span>
                                    </div>
                                </Link>



                                <Link to={"/gameRates"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/rate_stars.png" />
                                        <span>Game Rate</span>
                                    </div>
                                </Link>

                                

                                <Link to={"/gameRules"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/notice_nav.png" />
                                        <span>Game Rules</span>
                                    </div>
                                </Link>


                                <Link to={"/charts"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/charts.png" />
                                        <span>Charts</span>
                                    </div>
                                </Link>

                                {/* <Link to={"/winningHistorySelection"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/wallet.png" />
                                        <span>My Winnings</span>
                                    </div>
                                </Link>


                                <Link to={"/notifications"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/bell.png" />
                                        <span>Notifications</span>
                                    </div>
                                </Link> */}

{/* 
                                <Link to={"/deposit?mobile="+localStorage.getItem("mobile")}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/im_addfunds.png" />
                                        <span>Add Points</span>
                                    </div>
                                </Link>

                                <Link to={"/withdraw"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/im_withdrawfunds.png" />
                                        <span>Withdraw Points</span>
                                    </div>
                                </Link> */}

                                { this.state.transfer_point === "1" ?
                                <Link to={"/"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/transfer_coin.png" />
                                        <span>Transfer Coins</span>
                                    </div>
                                </Link> : "" }

                                {/* <Link to={"/howToPlay"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/play_button.png" />
                                        <span>How To Play</span>
                                    </div>
                                </Link> */}

                               
                                <Link onClick={()=>{shareApp()}} >
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/share.png" />
                                        <span>Share</span>
                                    </div>
                                </Link>

                                <a href="https://mastermatka.com/apk/master_matka.apk" target="_blank" >
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/cell-phone.png" />
                                        <span>Download App</span>
                                    </div>
                                </a>

                                <Link to={"/"} state={{logout:'true'}} >
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/logout_icon.png" />
                                        <span>Logout Account</span>
                                    </div>
                                </Link>

                            </div>

                        </div>
                        ) : "" }

                        <div className="bottomNav">
                            <Link to={"/myBids"}>
                            <div>
                                <img src="../images/my_bid.png" />
                                <span>My Bids</span>
                            </div>
                            </Link>

                            <Link to={"/passbook"}>
                            <div>
                                <img src="../images/passbook.png" />
                                <span>Passbook</span>
                            </div>
                            </Link>
                            
                            <div className="home">
                                <img src="../images/home_flat.png" />
                            </div>
                            
                            <Link to={"/funds"}>
                            <div>
                                <img src="../images/funds.png" />
                                <span>Funds</span>
                            </div>
                            </Link>
                            <a  onClick={handleSubmit} target="_BLANK">
                            <div>
                                <img src="../images/customer_support.png" />
                                <span>Support</span>
                            </div>
                            </a>
                        </div>

                        <div className="toolbar">

                            <div>
                                { localStorage.getItem("mobile") !== "9876543210" ? (
                                <img src="../images/three_dot.png" style={{marginRight:"5px"}} onClick={switchNav}/>
                                ) : "" }
                                <img className="wallet-img" src="../images/wide_logo.png"/>
                            </div>
                            { localStorage.getItem("mobile") !== "9876543210" ? (
                            <Link to={"/"}>
                                <div>
                                    <img className="wallet-img" src="../images/wallet_figma.png"/>
                                    <span className="wallet-text">{this.state.wallet}</span>
                                </div>
                            </Link>
                            ) : "" }


                        </div>
                        <div className="marquee">
                                <span>{this.state.homeMsg}</span>
                            </div>

                        <div className="actions">
                           
                            { localStorage.getItem("mobile") !== "9876543210" ? (
                            <div  className="colored">
                                

                              

                                <div>
                                    <div className="row homemenu">
                                    { this.state.starline == "1" ?
                                    <div className="col-6">
                                            <div className="homebigdiv">
                                                <Link to={"/starline"}>
                                                    <img src="../images/jackpot_play.png" />
                                                    <p>MAIN STARLINE</p>
                                                </Link>
                                            </div>
                                        </div>
                                     : "" }
                                        { this.state.gali == "1" ?
                                        <div className="col-6">
                                             <div className="homebigdiv">
                                            <Link to={"/gali"}>
                                                    <img src="../images/jackpot_play.png" />
                                                <p>MAIN JACKPOT</p>
                                            </Link>
                                            </div>
                                        </div>
                                         : "" }
                                    </div> 
                                </div>

                                <div>
                                    <div className="row homemenu">
                                  
                                    <div className="col-6">
                                            <div className="homebigdiv" style={{background:"#43a047"}}>
                                                <Link to={"/deposit"}>
                                                    <img src="../images/deposit_home_icon.png" style={{background:"white", borderRadius:"50px", padding:"2px"}} />
                                                    <p style={{color:"white"}}>ADD MONEY</p>
                                                </Link>
                                            </div>
                                        </div>
                                   
                                        <div className="col-6">
                                             <div className="homebigdiv"  style={{background:"##c62828"}}>
                                            <Link to={"/withdraw"}>
                                                    <img src="../images/withdraw_home_icon.png"  style={{background:"white", borderRadius:"50px", padding:"2px"}} />
                                                <p style={{color:"white"}}>WITHDRAW</p>
                                            </Link>
                                            </div>
                                        </div>
                                      
                                    </div> 
                                </div>

                                <div className="whatsappdiv">
                                     <a onClick={handleSubmit}  target="_BLANK">
                                    <div>
                                    <img src="../images/whatsapp (14)-min.png" />
                                    <span>{this.state.whatsappNum}</span>
                                    </div>
                                    </a>

                                    <a  onClick={handleSubmit}  target="_BLANK">
                                    <div>
                                    <img src="../images/whatsapp (14)-min.png" />
                                    <span>{this.state.whatsappNum}</span>
                                    </div>
                                    </a>
                                </div>
                            </div>
                            ) : "" }
                        </div>

                        <div style={{padding:"5px"}}>

                            {this.state.results &&

                                this.state.results.map((result) => {
                                    let marketStatus = ""
                                  if(result.is_close === "0") { marketStatus = "Market Closed" } else { marketStatus = "Market is Running" }
                                    return (

                            <div className="market_box" key={result.market}>
                             
                                <div  className="main">
                              
                                <div className="inner_market_box" style={{textAlign:"center"}}>
                               
                                    <span className="market_name">{result.market}</span>
                                    <span className="market_result">{result.result}</span>
                                    <div className="times">
                                    <span className="market_time" style={{marginRight:"15px"}}>Open Time:<br/>{result.open_time}</span>
                                <span className="market_time" >Close Time:<br/>{result.close_time}</span>
                                </div>
                                  
                                
                                </div>
                                { localStorage.getItem("mobile") !== "9876543210" ? (
                                <div className="inner_market_box" style={{alignItems:"center"}}>
                                    { result.is_close === "0" ?
                                        <span className="market_status" style={{color:"red"}}>{marketStatus}</span>
                                        :
                                        <span className="market_status" style={{color:"green"}}>{marketStatus}</span>
                                    }

                                    { result.is_close === "1" ?
                                    <Link to={'/games'} className="market_play_button_box"  state={[{market: result.market, is_open: result.is_open}]}>
                                        <img style={{width:"40px"}}  src="../images/market_open.png" />
                                        <span>Play Now</span>
                                    </Link>
                                        :   <Link className="market_play_button_box" onClick={()=>{
                                            showDialogError(result.open_time, result.open_time, result.close_time, result.close_time)
                                        }}  state={[{market: result.market, is_open: result.is_open}]}>
                                        <img style={{width:"40px"}}  src="../images/market_open.png" />
                                        <span>Play Now</span>
                                    </Link> }
                                    
                                </div>
                                ) : "" }
                                </div>
                            </div>
                                    )})}

                        </div>

                    </div>

                    </PullToRefresh>

                </div>
            </div>

            

<div className="review-bets-dialog" style={{display:this.state.dialogShow}}>
      <div className="dialog-content homedlg">
      
      <div className="dlgtoolbar">
            <span>GET ANDROID APPLICATION</span>
            <span style={{fontWeight:"900"}} onClick={()=>{hideDialog()}}>X</span>
        </div>


        <div className="main-dlg-content">
            <img className="appimg" src="../images/master_matka_app.png" />
            <div className="textblock">
                <p className="title">
                    DOWNLOAD OUR APP
                </p>
                <p className="desc">
                    Enjoy advanced features and options by downloading our Android app. access all the exciting features anytime, anywhere!
                </p>
                <a href="https://mastermatka.com/apk/master_matka.apk" target="_blank" className="button dlgbtn">
                    <img src="../images/arrow.png" />
                    <span>
                        Download Now
                    </span>
                </a>
            </div>
        </div>
        
      </div>
    </div>



    <div className="review-bets-dialog" style={{display:this.state.dialogShowError}}>
      <div className="dialog-content">
      
        <div className="bet-details" style={{flexDirection:"column", alignItems:"center"}}>

            <img src="../images/close_icon.png" style={{height:"75px", marginBottom:"14px"}} />
            <span style={{color:"red"}}>Betting is Closed For Today</span>
            <div className="towt">
            <div className="frs">
                
                <span>
                    Open Result Time :
                </span>
                
                <span>
                    Open Bid Last Time :
                </span>
                
                <span>
                    Close Result Time :
                </span>
                
                <span>
                    Close Bid Last Time :
                </span>
            </div>
         
            <div className="scd">
                
                <span>
                    {this.state.openR}
                </span>
                
                <span>
                    {this.state.openB}
                </span>
                
                <span>
                    {this.state.closeR}
                </span>
                
                <span>
                    {this.state.closeB}
                </span>
            </div>
        </div>
      
       
        <div className="dialog-actions" style={{justifyContent:"center", width:"100%", marginTop:"0"}}>
          <button className="button dlgbtn" onClick={()=>{hideDialogError()}}>OK</button>
        </div>
      </div>
    </div>
    </div>


        </>)
    }

}


export default Home;
